const config = {
  production: false,
  development: false,
  privateApiRoot: 'https://bahnkick-dev-private-api.azurewebsites.net/api',
  publicApiRoot: 'https://bahnkick-dev-api.azurewebsites.net/api',
  signalRHubUrl: 'https://bahnkick-dev-signalr.azurewebsites.net/api',

  hubName: 'bahnkick-dms-notifications-sandbox',
  hubEndpoint:
    'Endpoint=sb://bahnkick.servicebus.windows.net/;SharedAccessKeyName=DefaultListenSharedAccessSignature;SharedAccessKey=JwQvCKwQJVKlaVY29p94hGmCI5PDJwUYl22nCJ1F6WI=',

  LOG_ROCKET_KEY: '8kox0k/bahnkick-dealer-dev',
};

export default config;
